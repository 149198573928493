import { useState } from "react";
import { Page } from "../components/Page";
import { ContentWrapper } from "../components/articles/components/ContentWrapper";
import {
  Divider,
  Paragraph,
  Subtitle,
  Title,
} from "../components/articles/components/Typography";
import { twMerge } from "tailwind-merge";
import { RxArrowLeft } from "react-icons/rx";

enum HarmonyPageType {
  Harmony = 1,
  Power = 2,
}

export const HarmonyPage = () => {
  const [pageType, setPageType] = useState<HarmonyPageType>();

  return (
    <Page
      className="font-serif"
      backgroundSrc="https://files.catbox.moe/ygt9n5.jpg"
      customOpacity={55}
    >
      {!!pageType && (
        <button
          type="button"
          onClick={() => setPageType(undefined)}
          className="p-2 mb-5 hover:bg-opacity-90 hover:cursor-pointer bg-[#b2aca0] w-fit"
        >
          <RxArrowLeft className="w-5 h-5 text-white" />
        </button>
      )}

      <div className="flex gap-5 flex-col lg:flex-row mx-auto w-fit mb-10">
        <button
          type="button"
          onClick={() => setPageType(HarmonyPageType.Harmony)}
          className={twMerge(
            "bg-slate-600 bg-opacity-10 p-4 text-2xl text-center text-[#857e71]",
            pageType === HarmonyPageType.Power && "hidden"
          )}
        >
          <span className="uppercase">Harmonizace duše</span>
          <br />
          {pageType !== HarmonyPageType.Harmony && (
            <span className="underline text-current text-lg">více</span>
          )}
        </button>

        <button
          type="button"
          onClick={() => setPageType(HarmonyPageType.Power)}
          className={twMerge(
            "bg-slate-600 bg-opacity-10 p-4 text-2xl text-center text-[#857e71]",
            pageType === HarmonyPageType.Harmony && "hidden"
          )}
        >
          <span className="uppercase">O hledání správné míry</span>
          <br />
          {pageType !== HarmonyPageType.Power && (
            <span className="underline text-current text-lg">více</span>
          )}
        </button>
      </div>

      <div className="w-full lg:w-[900px] mx-auto">
        {!pageType && (
          <ContentWrapper>
            <Paragraph center>
              Jaké jsem jako člověk <span className="text-xl">podstaty</span>?
            </Paragraph>
            <Paragraph center>
              Co je mi <span className="text-xl">ještě</span> vlastní a{" "}
              <span className="text-xl">co už ne</span>?
            </Paragraph>
            <Paragraph center>
              Jak <span className="text-xl">až moc</span> ano a jak{" "}
              <span className="text-xl">až moc</span> už ne?
            </Paragraph>
            <Paragraph center>Jak sladit rozložení svých sil?</Paragraph>
            <Paragraph center>
              A co je potřebné <span className="text-xl">přímo</span> pro mě?
            </Paragraph>
          </ContentWrapper>
        )}

        {pageType === HarmonyPageType.Harmony && (
          <ContentWrapper>
            <Paragraph center>
              Existují chvíle v našem životě, které jsou nadmíru důležité pro to
              nejhlubší následné využití <span className="text-xl">přímo</span>{" "}
              pro <span className="text-xl">nás</span>
            </Paragraph>

            <Paragraph center>
              Je to moment, kdy se <span className="text-xl">obohacujeme</span>{" "}
              z okolí (přijímáme) a také, kdy{" "}
              <span className="text-xl">my</span> chceme{" "}
              <span className="text-xl">obohacovat</span> druhé (dáváme)
            </Paragraph>

            <Paragraph center>
              Jakákoliv <span className="text-xl">neúměrná míra</span> však
              vysílá do okolí <span className="text-xl">disharmonii</span>
            </Paragraph>

            <Subtitle center>Jak vysílat tedy jen Harmonii?</Subtitle>

            <Subtitle className="mt-5" center>
              1. nejdůležitější pilíř
            </Subtitle>
            <Divider center />
            <Title center>Poznání podstaty jádra duše</Title>
            <Paragraph center>
              Každý jednotlivý člověk disponuje svými prvotními záchvěvy.
            </Paragraph>
            <Paragraph center>
              A ten jako takový v sobě nese svůj vrytý původní rys, který je{" "}
              <span className="text-xl">nutné poznat</span>.
            </Paragraph>
            <Paragraph center>
              Z jeho jádra pak vyzařují zcela konkrétní „mosty“ - proudy, které
              čekají na své utváření a doplnění v{" "}
              <span className="text-xl">čisté</span> a{" "}
              <span className="text-xl">správné</span> míře.
            </Paragraph>
            <Paragraph center>
              A to pro zcela konkrétní částečky -hodnot a oblastí života. Také i
              způsoby jednání a veškerý projev{" "}
              <span className="text-xl">sladěný</span> s{" "}
              <span className="text-xl">jádrem konkrétního</span> člověka
            </Paragraph>
            <Title center>- Pojďme si v tom společně pomáhat -</Title>

            <Subtitle className="mt-5" center>
              2. pilíř
            </Subtitle>
            <Divider center />
            <Title center>Vycítění správné míry</Title>
            <Paragraph center>
              Nalezení <span className="text-xl">úměrného</span> rozložení sil
              je tak pro každého jedince a chvíli{" "}
              <span className="text-xl">odlišné</span>, i kdyby se
            </Paragraph>
            <Paragraph center>
              nacházel v naprosto <span className="text-xl">stejných</span>{" "}
              podmínkách a okolnostech.
            </Paragraph>
            <Paragraph center>
              Můžeme tak spolu poznávat onu{" "}
              <span className="text-xl">jedinečnou úměru</span>
            </Paragraph>
            <Paragraph center>
              nuance pro konkrétní <span className="text-xl">chvíli</span>.
            </Paragraph>
            <Paragraph center>
              Tedy co je pro daného jedince{" "}
              <span className="text-xl">potřebné</span> k vývoji a nevede
            </Paragraph>
            <Paragraph center>
              jen ke <span className="text-xl">zdánlivé</span> spokojenosti.
            </Paragraph>

            <Subtitle className="mt-5" center>
              3. pilíř
            </Subtitle>
            <Divider center />
            <Title center>Nalezení vlastního proudu</Title>
            <Paragraph center>
              Najděme spolu ony <span className="text-xl">konkrétní</span> místa
              v našem životě, která nás
            </Paragraph>
            <Paragraph center>
              svou <span className="text-xl">přemírou</span> nebo{" "}
              <span className="text-xl">podmírou</span> vykolejují z{" "}
              <span className="text-xl">rovnováhy</span>.
            </Paragraph>
            <Paragraph center>
              Každý si přináší své jedinečné hodnoty. Avšak někdy si příliš
              velkým nebo příliš malým
            </Paragraph>
            <Paragraph center>
              úsilím o tyto částečky – hodnoty - vytváříme disharmonii v
              konkrétní oblasti života.
            </Paragraph>
            <Paragraph center>
              Nalezněme tak{" "}
              <span className="text-xl">onen nejharmoničtější</span> bod mezi
              nimi.
            </Paragraph>
            <Paragraph center>
              Člověk je často ztracený <span className="text-xl">mezi</span>{" "}
              břehy dvou polarit
            </Paragraph>
            <Paragraph center>Ano – Ne</Paragraph>
            <Divider center />
            <Paragraph center>
              <span className="text-xl">Vůle</span> –{" "}
              <span className="text-xl">Lásky</span>
            </Paragraph>
            <Paragraph center>Přítlaku – Odtlaku</Paragraph>
            <Paragraph center>Přísnosti - Laskavosti</Paragraph>
            <Paragraph center>Mírnosti – Průbojnosti</Paragraph>
            <Subtitle center>aj. hodnot a způsobů protikladů</Subtitle>

            <Title center>
              Navraťme se už teď ke svému vlastnímu proudu, <br />
              avšak <span className="text-2xl">čistě obohacení</span> pestrostí
              Země. Stůjme tak v ryzosti{" "}
              <span className="text-2xl">svého Ducha</span>
            </Title>
          </ContentWrapper>
        )}

        {pageType === HarmonyPageType.Power && (
          <ContentWrapper>
            <Paragraph center>
              Člověk tam v hloubi duše nese svoji čistou prvotní podstatu –
              jistý svůj záchvěv. Jeho celoživotním úkolem je v každé jednotlivé
              přítomnosti narovnat svůj projev a způsob jednání do správné míry
              tak, aby stál vyváženě se svou přirozeností.
            </Paragraph>

            <Paragraph center>
              Každá chvíle je tak o hledání onoho nejharmoničtějšího bodu mezi
              dvěma částmi – Vůle a Lásky. A stejně tak o hledání správné míry
              mezi dvěma polaritami jejich hodnot. Mezi přísností – laskavostí,
              průbojností – mírností, tzv. přítlaku a odtlaku a dalších
              protipólů vlastností.
            </Paragraph>

            <Paragraph center>
              Proto je velkým darem zralý vztah mezi mužem a ženou. Kdy jeden
              stojí více ve vůli a druhý v lásce a společně se svými přemírami a
              podmírami vzájemně obohacují.
            </Paragraph>

            <Paragraph center>
              Avšak mezistupňů mezi Vůlí a Láskou je nespočet a je třeba najít
              mezi nimi tu svoji vlastní polohu. Je to jako výroba pokrmů z
              miliónů prvků z různých počtů. Rozpoznat však přesně tzv. mix
              ingrediencí jednotlivých záchvěvů, které se vzájemně prolínají, je
              na srdci člověka.
            </Paragraph>

            <Paragraph center>
              Lidé v Čechách stojí obecně více ve vůli než na Moravě. A přesto
              onen pár právě z Čech mezi sebou zase prožívá doplnění Vůle a
              Lásky na jisté úrovni svého vlastního obohacení. Je to jako úsečka
              protipólů v další úsečce protipólů. Zkuste se na to podívat z
              pohledu matematických množin.
            </Paragraph>

            <Paragraph center>
              Vezmeme-li v potaz ještě i fyziku (přitahování + a -) a obor
              chemie se svým slučováním prvků (atomů) v molekuly, může vzniknout
              jednou obor „Obecné Harmonie“.
            </Paragraph>

            <Paragraph center>
              A tak můžeme jít od národů, krajů a měst až do vztahů
              partnerského. Je to jako skládačka atomů do molekul. Svět je
              Sloučením jednotlivých prvků rozdílností a to nejen materií, ale
              má být i vlastností a hodnot ve správných mírách, chceme-li tu
              vybudovat harmonický ráj. Avšak budujeme-li v nesprávných mírách,
              dopadá to tak, jako vidíme za našimi okny.
            </Paragraph>

            <Paragraph center>
              Každý nesoulad v člověku je výrazně viditelný a působí na druhé
              nepřirozeně. Mnohdy tak v denním životě do sebe přejímá krásné,
              avšak - celé obecné pojmy. Místo toho, aby hledal onu správnou
              míru jejich užití pro svůj vlastní život. Zde je třeba si
              uvědomit, že stejně jako ty nepříjemné, tak ale i ty příjemné
              okolnosti mohou negativně ovlivňovat naši přirozenost. A to pokud
              je v našem projevu a způsobu života užíváme cele a v podobě, která
              nesouzní už s naší přirozenou podstatou.
            </Paragraph>

            <Paragraph center>
              Každý z nás jistě už alespoň jednou hledal v životě nějaké řešení.
              Poté přišla pomoc v podobě rady, zkušenosti nebo způsobu života
              někoho jiného. Nebo jsme vyloženě jen tak někoho pozorovali a
              nechali se inspirovat. Taková situace bývá mnohdy opravdu pro náš
              vývoj, aby nám přispěla něčím, čeho my ještě tolik nemáme. Avšak
              to – kolik a jak - toho v dané přítomnosti máme přijmout ke svému
              ucelení, závisí na přirozeném sladění s naší vlastní podstatou.
              Zde platí - nebrat plně a cele, co se nabízí.
            </Paragraph>

            <Paragraph center>
              Jsou lidé, kteří pro zdravou stravu nevidí pružnější a mnohem
              širší cesty, které i tu zdravou stravu v jisté v živě se měnící
              míře obsahují. Natolik dovedli zdravé stravování k dokonalosti, že
              mají-li v nenadálé situaci, nutné pro jejich vývoj, na menší či
              větší chvíli od ní upustit, neudělají to.
            </Paragraph>

            <Paragraph center>
              Ovlivňují tak svým neharmonickým způsobem mnohdy i své blízké. A
              tak je to v oblékání, ve svých zálibách, práci či péči o své
              blízké nebo i o čistotu domova. Také přehnaná nebo naopak žádná
              přízeň se může stát strnulostí. Rovnováha však obsahuje vše v těch
              správných polohách. Tudíž ušitá na míru štěstí každého v
              přítomnosti.
            </Paragraph>

            <Paragraph center>
              Ona disharmonie v míře zasahuje i do navštěvování - nenavštěvování
              svých blízkých a přátel, průbojnosti - mírnosti i sdělování -
              nesdělování informací atd. Všude nás vybízí k nalezení onoho
              správného bodu pro nás, jak až moc ano a jak až moc ne. Vše se dá
              ve svých extrémech přehánět anebo naopak brát lhostejně. Avšak i
              extrém je za určitých okolností třeba. Co je však nejpotřebnější,
              je oživení pružného cítění a úměrné rozdělování své vlastní síly.
            </Paragraph>

            <Paragraph center>
              Dalším příkladem jsou ti, kteří se uvrtali do vaření až nevaření,
              způsobu šacení, svých koníčků nebo naopak pasivity, péče až nepéče
              o druhé atd. Také sportování až nesportování, cvičení až necvičení
              je natolik zneužito, že zmátlo mnoho lidí. A tak je to v každé
              částečce života.
            </Paragraph>

            <Paragraph center>
              Spousta lidí je už tak blízko ušlechtilé, tedy přirozené kráse
              svého původu. Stačilo by jen tu a tam a v jisté chvíli a v dané
              oblasti jejich života pootočit kolečkem, „ubrat či přidat". Sladit
              vše tak, aby stálo vyváženě. Přesně tak, jako když hledáme tu
              jistou frekvenci na rádiu a musíme naladit ty správné vlny. Stejné
              je to s vyvážením obecných pojmů, které tak „nasáváme" do svého
              projevu a způsobu jednání. Máme na výběr spousty těch, které jsou
              velmi hodnotné a oplývají krásou. Avšak člověk strnulý bude
              strnule těchto pojmů užívat. Ten přirozený uzpůsobí míru užití
              těchto pojmů každé oblasti svého života. My máme usilovat v každém
              momentě o nalezení toho nejharmoničtějšího bodu mezi vším.
            </Paragraph>

            <Paragraph center>
              Avšak všechny disharmonie na Zemi tak trochu nevědomě slouží i ku
              pomoci. A to proto, že svou přemírou či podmírou jsou natolik
              přitahující pro ty, kteří jsou v jisté míře v opaku. Pokud by
              nebyly až příliš viditelné, nedocházelo by tolik k obohacení se –
              nebylo by to totiž pro lidi natolik podvědomě či vědomě zřejmé.
            </Paragraph>

            <Paragraph center>
              Přitom by stačilo onu přemíru či podmíru úsilí, kterou si tak rádi
              hýčkáme, vzít a věnovat ji tam, kde neustále ještě hledáme v
              životě štěstí. Často se nám tento vzorec až tak nápadně ukazuje,
              že jej nedokážeme vidět! Problém je však v nedostatku chtění po
              sloučení dvou a více rozdílných částeček.
            </Paragraph>

            <Paragraph center>
              Problém nastává, když ona podstata - vlivem těžkých životních
              událostí - přejde úplně na opačný břeh své hodnoty z oné polarity,
              která je jí vlastní. Takto mnohdy každým životem přechází tam a
              zpět a stává se čím dál více zmatenější. Přebírá na sebe zcela
              cizí způsoby a projevy a působí tak na okolí nepřirozeně.
            </Paragraph>

            <Paragraph center>
              Většinou jsou to právě hodnoty jejích ctností, které člověka
              uvrhly nesprávným užitím do její nepřirozenosti. Každá ctnost v
              sobě nese zesílený tlak (sílu) pro její užití. Tato zvýšená síla
              dotyčného nutká ve svém mohutném vlnění - k provedení do činu.
              Pokud takový člověk však nevyužije správně onu ctnost a
              nenasměruje ji správným směrem, může jej zcela pohltit. Takový
              člověk ji pak vnímá někde ve své hloubi už jen jako bublající
              opar. Tato ctnost tak zůstala „viset" v konkrétní oblasti našeho
              života a tam zahnívá společně s našimi útrapami.
            </Paragraph>

            <Paragraph center>
              Tak můžeme vidět člověka typu zemského - přírodního typu a zcela
              dokonalého ve způsobu svého stravování. Jako takový ve svém celku
              vytváří kolem sebe vysoce přirozený (přírodní) záchvěv Země,
              kterým oblažuje svět. Avšak možné široké využití této ctnosti
              zúžil např. na prachobyčejné třídění odpadu. Tak urputně dává
              jisté věci přednost před celostním a širším využitím této vyšší
              hodnoty.
            </Paragraph>

            <Paragraph center>
              Téměř vždy je to jedna konkrétní životní událost, která jej v
              některém životě k tomu dohnala a teď jej stravuje. Avšak její
              původ z něj prozařuje dále. Jeho projev a způsoby života už ale
              vykazují známky disharmonie.
            </Paragraph>

            <Paragraph center>
              Pak máme lidi, kteří říkají, že nemají čas. Je spousty takových
              příběhů a nechci soudit opravdu těžká prožití. Ale právě takoví
              lidé příliš přehánějí úsilí v tom, co v této míře a pro svůj vývoj
              nepotřebují v dané oblasti života a kterou mají zde už zcela
              zvládnutou. Vlastně už po nich nikdo nežádá její další stupňování,
              jen oni sami. A život jen čeká, až se jí částečně pustí a
              přesměrují kus z tohoto úsilí tam, kde jej pro svůj vývoj ještě
              potřebují.
            </Paragraph>

            <Paragraph center>
              Dalším příkladem může být čistota, která též doznala zkřivení
              svého pojmu. Všimněte si, že člověk nesoucí ve svém původním hávu
              čistotu a oplývající v původu čistotou, toto úsilí vynakládá
              například až příliš do úklidu. Zapomněl, že čistota má vycházet z
              jeho veškerého jednání plně a na všech frontách. On ji tak na
              místo toho vměstnal do úzké hranice pozemského úklidu. Tak i zde
              je to mnohdy jedna životní zkušenost (prožití traumat a silných
              otřesů), která omezila jeho čistotu na úklid a uzavřela mu mnohem
              zářivější možnosti.
            </Paragraph>

            <Title center>
              Všechny tyto a další příklady existují v miliónech odstupnění a
              závisí na nespočtu okolností.
            </Title>

            <Title center>
              Změnili bychom byť jen jednu částečku z nich v přítomnosti, či
              odstín jednoho charakteru z těch tisíců, celá stavba po harmonii
              musí být někdy zcela přeskupena.
            </Title>

            <Title center>Ať už nyní promlouvá jen cit!</Title>
          </ContentWrapper>
        )}
      </div>
    </Page>
  );
};
