import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Navbar } from "./components/Navbar";

import "./index.css";

import { ArticlePage } from "./pages/ArticlePage";
import { CreationsPage } from "./pages/CreationsPage";
import { ContactPage } from "./pages/ContactPage";
import { Photogallery } from "./pages/GalleryPage";
import { LandingPage } from "./pages/LandingPage";
import { HarmonyPage } from "./pages/HarmonyPage";
import { ThanksPage } from "./pages/ThanksPage";
import { WorkMeaningPage } from "./pages/WorkMeaning";
import { HarmonySpiritPage } from "./pages/HarmonySpiritPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/kontakt",
    element: <ContactPage />,
  },
  {
    path: "/fotogalerie",
    element: <Photogallery />,
  },
  {
    path: "/work-meaning",
    element: <WorkMeaningPage />,
  },
  {
    path: "/new-today",
    element: <HarmonyPage />,
  },
  {
    path: "/creations",
    element: <CreationsPage />,
  },
  {
    path: "/article",
    element: <ArticlePage />,
  },
  {
    path: "/thanks",
    element: <ThanksPage />,
  },
  {
    path: "/harmony",
    element: <HarmonySpiritPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Navbar />
    <RouterProvider router={router} />
  </React.StrictMode>
);
