import { Page } from "../components/Page";
import { ContentWrapper } from "../components/articles/components/ContentWrapper";
import { Paragraph, Title } from "../components/articles/components/Typography";

export const WorkMeaningPage = () => {
  return (
    <Page
      backgroundSrc="https://files.catbox.moe/8pxd96.jpg"
      customOpacity={70}
      className="font-serif"
    >
      <div className="lg:w-[900px] w-fit mx-auto">
        <ContentWrapper>
          <Paragraph center>
            Narodil jsem se a bydlím i nyní ve Zlíně. Mým celoživotním vzděláním
            je ničím <span className="text-xl">nekončící škola života </span> a
            ono věčné hledání toho{" "}
            <span className="text-xl">nejharmoničtějšího bodu</span> v
            mezilidském jednání.
          </Paragraph>

          <Paragraph center>
            Mé jméno je Tomáš Dorničák. Je mi 37 a už 5 let pracuji jako
            zahradník. Tato práce mě denně po léta inspiruje a i díky ní teď
            rozšiřuji své snahy také do oblasti{" "}
            <span className="text-xl">„Hledání správné míry“</span> v lidském
            životě.
          </Paragraph>

          <Paragraph center>
            To, proč jsem předtím prošel v tak krátkém období až nezvyklým
            množstvím prací (15 prací během 13 let), různých pracovních poměrů
            včetně toho v zahraničí i obecně cestováním, mi došlo až po mnoha
            letech. S tím také spojená neustálá změna bydlení a všeho, co mě
            obklopovalo, mi začalo dávat smysl až později.
          </Paragraph>

          <Paragraph center>
            Začal jsem postupně hlouběji nahlížet do duší lidí až k jejich ryzím
            podstatám. Spatřovat tak určitý nesoulad toho, co{" "}
            <span className="text-xl">
              vydávají nebo naopak přijímají z okolí v nesprávné míře
            </span>
            . Začal jsem si tak uvědomovat, že nejen to nehezké,{" "}
            <span className="text-xl">ale i to hezké</span>, co k nám přichází,
            může velmi <span className="text-xl">negativně</span> ovlivnit náš
            život.
          </Paragraph>

          <Paragraph center>
            Pochopil jsem, že mám tak poznávat co nejvíce{" "}
            <span className="text-xl">rozdílných</span> lidí v{" "}
            <span className="text-xl">různých</span> oborech, skupinách i
            životních situacích. V jednotlivých oblastech života od „kopáčů“ až
            po obchodní zástupce; jak z nejnižších, tak bohatých vrstev
            společnosti. A také, že je mám – jako takové - hlouběji poznávat a
            milovat. Za ta léta jsem do sebe „nasál“ spousty prostorů a oblastí,
            které mě teď nutkají ke zužitkování.
          </Paragraph>

          <Paragraph center>
            Spousty lidí v nich už bylo nějak „ukotvených“, jen já spatřoval čím
            dál větší krásu <span className="text-xl">v co nejširším</span>{" "}
            poznávání jednotlivých rozdílností. Nikde jsem dlouho nevydržel –
            přitahovala mě <span className="text-xl">pestrost</span> veškerého
            světa.
          </Paragraph>

          <Paragraph center>
            Chtěl jsem-li doznat jména <span className="text-xl">Harmonie</span>
            , bylo nutné poznat{" "}
            <span className="text-xl">co nejvíce podstat jednotlivostí</span>{" "}
            Země, a to na mnoha úrovních napříč společností. Nikdy jsem netoužil
            po „ukotvení“ v jednom jistém oboru.{" "}
            <span className="text-xl">Mým</span> ukotvením{" "}
            <span className="text-xl">bylo</span> a{" "}
            <span className="text-xl">je nejširší</span> poznávání lásky a v tom
            být užitečný.
          </Paragraph>

          <Title center>
            Hledání těchto cest je pro mě posláním a mojí osobní cestou k Bohu
          </Title>

          <Title center>Tomáš Dorničák</Title>
        </ContentWrapper>
      </div>
    </Page>
  );
};
