import { ArticleShow } from "../components/ArticleShow";
import { Page } from "../components/Page";

export const CreationsPage = () => {
  return (
    <Page noVerticalPadding>
      <section className="flex flex-col gap-2.5 mx-auto w-fit">
        <img
          src="https://files.catbox.moe/8o0hnq.png"
          className="h-48 mx-auto w-fit opacity-55"
          alt="Cover"
        />
        <ArticleShow />
      </section>
    </Page>
  );
};
