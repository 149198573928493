import { Page } from "../components/Page";

export const ThanksPage = () => {
  return (
    <Page
      className="flex items-center justify-center min-h-screen font-serif text-center"
      backgroundSrc="/assets/background.jpg"
      customOpacity={65}
      noVerticalPadding
    >
      <div className="max-w-4xl px-4">
        <h1 className="z-10 mb-5 lg:mt-[-100px] text-xl text-gray-500">
          Opravdu velké poděkování patří mému bráchovi Kubovi, který pro nás
          tyto stránky vytvořil. Měl se mnou tu velkou trpělivost a ochotu vyjít
          mi vstříc i za cenu významné části jeho osobního času
        </h1>
        <h1 className="z-10 text-xl text-gray-500">
          V druhé řadě bych chtěl také vyjádřit vděčnost za ztvárnění ilustrací
          mé ženě Terezce. To, jak přesně dokázala vystihnout ducha těchto
          stránek svými obrázky, za to jí patří veliký dík
        </h1>
        <h1 className="z-10 pt-10 text-xl text-gray-500 md:text-2xl">
          Stránky Hlasy Země
        </h1>
      </div>
    </Page>
  );
};
