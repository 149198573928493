import { Page } from "../components/Page";

export const ContactPage = () => {
  return (
    <Page
      customOpacity={75}
      backgroundSrc="https://files.catbox.moe/ognfty.jpg"
    >
      <div className="z-20 mx-auto mt-[100px] text-[#857e71] text-center w-fit">
        <p className="mb-2 text-gray-500 text-lg">
          Máte-li zájem se dozvědět více, nebo si jen tak o tomto tématu sdílet,
          napište. <br /> Téma těchto stránek je pro mě natolik naplňující, že
          budu rád za vše společné
        </p>
        <p className="text-2xl text-gray-500">Email</p>
        <hr className="border-gray-400 mt-3 mb-3 w-[300px] lg:w-[1100px]" />
        <a href="mailto:hlasy-zeme@email.cz">
          <p className="text-3xl">hlasy-zeme@email.cz</p>
        </a>
      </div>
    </Page>
  );
};
