import { ContentWrapper } from "../components/articles/components/ContentWrapper";
import {
  Divider,
  Paragraph,
  Title,
} from "../components/articles/components/Typography";
import { Page } from "../components/Page";

export const HarmonySpiritPage = () => {
  return (
    <Page
      backgroundSrc="https://files.catbox.moe/8pxd96.jpg"
      customOpacity={70}
      className="font-serif"
    >
      <ContentWrapper>
        <Title className="text-2xl" center>
          Hledám lidi, kteří ...
        </Title>

        <Paragraph center>
          mají alespoň nějaké znalosti z vědních oborů
        </Paragraph>
        <Paragraph center>(chemie, fyziky, matematiky aj.)</Paragraph>
        <Paragraph center>Rádi by společně probádali</Paragraph>
        <Paragraph center>
          souvislosti mezi nimi a mezilidskými vztahy.
        </Paragraph>
        <Paragraph center>
          Pojďme poznávat náš svět do hloubky až k prvotním
        </Paragraph>
        <Paragraph center>prvkům a přírodním zákonům Země.</Paragraph>
        <Paragraph center>Nacházením podobností</Paragraph>
        <Paragraph center>s lidskými životy.</Paragraph>
        <Paragraph center>
          Vystavme tak obor „Obecné Harmonie Vztahovosti“
        </Paragraph>
        <Divider center />

        <Title className="text-2xl" center>
          K 5.1 2025 vyjde první část úvodního dílu Obecné Harmonie Vztahovosti
        </Title>
        <Title className="text-2xl" center>
          s názvem Vědní obory jako uznání přírodních zákonů
        </Title>
      </ContentWrapper>
    </Page>
  );
};
